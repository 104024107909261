<template>
  <div class="navbar xl:px-10 sm:px-5 px-3 md:py-4 py-3 flex items-center justify-between lg:mx-7 mx-4">
    <div class="flex items-center">
      <div class="xl:hidden flex md:mr-8">
        <img class="cursor-pointer" @click="openMenubar" width="23px" height="16px" src="@/assets/images/menu-bar.svg" alt="">
      </div>
      <img class="md:flex hidden" src="@/assets/images/shuttle-logo.svg" alt="">
      <div class="ml-8 xl:flex hidden">
        <ul class="nav-items">
          <li>
            <router-link :to="`/${$route.params.lang}/home`" class="px-4">{{ $t('Home') }}</router-link>
          </li>
          <li>
            <router-link :to="`/${$route.params.lang}/received-products`" class="px-4">{{ $t('Received Products') }}</router-link>
          </li>
          <li>
            <router-link :to="`/${$route.params.lang}/outgoing-shipments`" class="px-4">{{ $t('Outgoing Shipments') }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <img class="md:hidden flex sm:w-34 w-32" src="@/assets/images/myShipBlu-logo.svg" alt="">
    <div class="flex items-center">
      <div class="language-box md:px-4 px-2" @click="changeLocale" :class="$route.params.lang === 'en' ? 'md:py-1 pb-1' : 'py-1 py-1'">
        <p class="md:block hidden font-cairo">Eng / عربى</p>
        <p class="md:hidden block font-cairo">{{$route.params.lang === 'en' ? 'ع' : 'En'}}</p>
      </div>
      <div class="notifications md:mx-3 md:px-3 sm:ml-3 sm:pl-3 ml-2 pl-2">
        <img class="cursor-pointer" src="@/assets/images/notification-active.svg" alt="">
      </div>
      <div class="flex items-center md:flex hidden">
        <p class="text-blue text-sm font-dmSans mr-3">{{ userData.full_name }}</p>
        <button class="profile-img relative">
          <img class="w-8 h-8 cursor-pointer rounded-full" :src="$store.state.AppActiveUser.photoURL" alt="">
          <ul class="dropdown">
            <li class="py-2 px-3 text-xs flex items-center font-medium" @click="logout">
              <img class="rtl-rotate-icon mr-2" src="@/assets/images/logout-icon.svg" alt="">
              <span>{{ $t('Log Out') }}</span>
            </li>
          </ul>
        </button>
      </div>
    </div>
    <div class="menu-bar" 
      :class="(menuFlag && $route.params.lang === 'en') ? 'left-menu-bar-active'
      : (menuFlag && $route.params.lang === 'ar') ? 'right-menu-bar-active' : 'menu-bar-inactive'">
      <div class="relative h-full">
        <div class="flex justify-between">
          <img src="@/assets/images/myShipBlu-logo.svg" alt="">
          <span @click="closeMenubar" class="material-icons text-base text-darkblue cursor-pointer mt-1">close</span>
        </div>
        <div class="hr-border mt-5" />
        <div class="mt-5 leading-tight">
          <p class="text-eggplant capitalize font-medium text-sm">{{ userData.full_name }}</p>
          <p class="text-darkgray font-medium text-xs mt-1">{{ userData.email }}</p>
          <p class="text-xs capitalize leading-tight mt-2">
            <span class="text-gray">{{ $t('myshipblu Box No.') }}</span>
            <span class="font-semibold text-blue ml-2">{{ userData.po_box.box_number }}</span>
          </p>
        </div>
        <div class="hr-border mt-5" />
        <div class="mt-5">
          <div @click="closeMenubar" class="flex items-center cursor-pointer leading-tight font-medium text-sm capitalize text-eggplant">
            <img class="mr-4 w-4 h-4 rtl-rotate-icon" src="@/assets/images/home-icon.svg" alt="">
            <router-link :to="`/${$route.params.lang}/home`">{{ $t('Home') }}</router-link>
          </div>
          <div @click="closeMenubar" class="flex items-center cursor-pointer leading-tight font-medium text-sm capitalize text-eggplant mt-4">
            <img class="mr-4 w-4 h-4 rtl-rotate-icon" src="@/assets/images/received-product-icon.svg" alt="">
            <router-link :to="`/${$route.params.lang}/received-products`">{{ $t('Received Products') }}</router-link>
          </div>
          <div @click="closeMenubar" class="flex items-center cursor-pointer leading-tight font-medium text-sm capitalize text-eggplant mt-4">
            <img class="mr-4 w-4 h-4 rtl-rotate-icon" src="@/assets/images/outgoing-shipment-icon.svg" alt="">
            <router-link :to="`/${$route.params.lang}/outgoing-shipments`">{{ $t('Outgoing Shipments') }}</router-link>
          </div>
        </div>
        <div class="hr-border mt-5" />
        <div class="absolute bottom-0 w-full">
          <div class="hr-border" />
          <div class="mt-5 flex items-center" @click="logout">
            <img class="rtl-rotate-icon" src="@/assets/images/logout-icon.svg" alt="">
            <p class="cursor-pointer capitalize text-eggplant text-sm leading-tight ml-3">{{ $t('log out') }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['userData'],
  data () {
    return {
      menuFlag: false
    }
  },
  methods: {
    openMenubar () {
      this.menuFlag = true
    },
    closeMenubar () {
      this.menuFlag = false
    },
    changeLocale () {
      const locale = this.$route.params.lang === 'en' ? 'ar' : 'en'
      this.$cookies.set('locale', locale, '100y')
      localStorage.setItem('locale', locale)
      const dir = this.$cookies.get('locale') === 'en' ? 'ltr' : 'rtl'
      document.documentElement.setAttribute('dir', dir)
      this.$router.push({
        params: {lang: this.$cookies.get('locale')}
      }).catch(() => {})
      this.$i18n.locale = this.$cookies.get('locale')
    },
    logout () {
      if (this.$auth.profile) this.$auth.logOut()
    }
  }
}
</script>

<style lang="scss" scoped src="@/assets/css/styles/navbar.scss"></style>