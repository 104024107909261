<template>
<div>
  <div class="main">
    <div class="h-6 hide-main"></div>
    <navbar :userData="userData"/>
    <router-view />
  </div>
</div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import {sendRequest} from '../../axios/requestHelper.js'

export default {
  data () {
    return {
      userData: {
        po_box: {}
      }
    }
  },
  components: {
    Navbar
  },
  methods: {
    loadUserInfo () {
      sendRequest(this, `api/customers/${this.$store.state.AppActiveUser.userID.customer_id}/`, 'get', null, true,
        (response) => {
          this.userData = response.data
          localStorage.setItem('userData', JSON.stringify(response.data))
        }
      )
    }
  },
  created () {
    if (localStorage.getItem('userData') === null) {
      this.loadUserInfo()
    } else {
      this.userData = JSON.parse(localStorage.getItem('userData'))
    }
  }
}
</script>